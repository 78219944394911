export const paymentsType = [
	{
		value: 'Sin especificar',
		label: 'Sin especificar',
	},
	{
		value: 'Contado',
		label: 'Contado',
	},
	{
		value: 'Financiado',
		label: 'Financiado',
	},
	{
		value: 'Plan D',
		label: 'Plan D',
	},
	{
		value: 'Permuta',
		label: 'Permuta',
	},
];

import React, { useState } from 'react';
import { useFormik } from 'formik';
import { ClientSearchbar } from './ClientSearchbar';
import { ToastContainer, toast } from 'react-toastify';
import {
	Card,
	Box,
	Radio,
	Select,
	Tooltip,
	SvgIcon,
	MenuItem,
	TextField,
	IconButton,
	Typography,
	useMediaQuery,
	OutlinedInput,
	InputAdornment,
} from '@mui/material';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ReplayIcon from '@mui/icons-material/Replay';
import MagnifyingGlassIcon from '@heroicons/react/24/solid/MagnifyingGlassIcon';

import 'react-toastify/dist/ReactToastify.css';

export const ClientSearch = ({
	search,
	stateValue,
	changeState,
	totalStates,
	resetFilters,
	advisors = [],
	zonesLaplata = [],
	zonesMendoza = [],
	setSelected = () => {},
	setSelectAll = () => {},
}) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const smallScreen = useMediaQuery('(max-width:1230px)');

	const location = useLocation();

	const handleResetFilters = () => {
		setSelectAll(false);
		setSelected([]);
		formik.resetForm();
		dispatch(resetFilters());
	};

	const formik = useFormik({
		initialValues: initialValues(),
		validationSchema: validationSchema(),
		validateOnChange: false,
		onSubmit: async formValue => {
			try {
				setIsLoading(true);

				const queries = [];
				if (formValue.zone !== 0) queries.push(`zone=${formValue.zone}`);
				if (formValue.search.length > 1) queries.push(`cellphone=${formValue.search}`);
				if (formValue.filter !== 0) queries.push(`idAdvisor=${formValue.filter}`);

				if (queries.length > 0) {
					const queryString = queries.join('&');
					await dispatch(search(queryString));
				} else {
					toast.error('Ingrese un filtro.', { theme: 'colored' });
				}
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
			}
		},
	});

	return (
		<>
			<ClientSearchbar
				stateValue={stateValue}
				changeState={changeState}
				totalStates={totalStates}
				setSelected={setSelected}
				setSelectAll={setSelectAll}
			/>

			<Card sx={{ p: 2, mt: '5px !important' }}>
				<form
					onSubmit={formik.handleSubmit}
					style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
				>
					<Box sx={{ display: 'flex', flexDirection: 'row' }}>
						<OutlinedInput
							fullWidth
							placeholder='Buscar...'
							name='search'
							value={formik.values.search.trim()}
							onChange={formik.handleChange}
							error={!!formik.errors.search}
							startAdornment={
								<InputAdornment position='start'>
									<SvgIcon color='action' fontSize='small'>
										<MagnifyingGlassIcon />
									</SvgIcon>
								</InputAdornment>
							}
							sx={{ width: 270 }}
						/>

						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								marginRight: '15px',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Radio
									checked={formik.values.selectedZone === 'laPlata'}
									value='laPlata'
									size='small'
									name='selectedZone'
									onChange={formik.handleChange}
									inputProps={{ 'aria-label': 'La Plata' }}
									sx={{ height: '15px' }}
								/>
								<Typography fontSize={14}>Buenos Aires</Typography>
							</Box>

							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center',
								}}
							>
								<Radio
									checked={formik.values.selectedZone === 'mendoza'}
									size='small'
									value='mendoza'
									name='selectedZone'
									onChange={formik.handleChange}
									inputProps={{ 'aria-label': 'Mendoza' }}
									sx={{ height: '15px' }}
								/>
								<Typography fontSize={14}>Mendoza</Typography>
							</Box>
						</Box>

						{formik.values.selectedZone === 'laPlata' && (
							<TextField
								label='Buenos Aires'
								name='zone'
								select
								SelectProps={{ native: true }}
								value={formik.values.zone}
								error={!!formik.errors.zone}
								onChange={formik.handleChange}
							>
								<option key={9999} value={0}>
									Sin especificar
								</option>
								{zonesLaplata.map((e, i) => (
									<option key={i * Math.random()} value={e.id}>
										{e.description}
									</option>
								))}
							</TextField>
						)}

						{formik.values.selectedZone === 'mendoza' && (
							<TextField
								label='Mendoza'
								name='zone'
								select
								SelectProps={{ native: true }}
								value={formik.values.zone}
								error={!!formik.errors.zone}
								onChange={formik.handleChange}
							>
								<option key={9999} value={9999}>
									Sin especificar
								</option>
								{zonesMendoza.map((e, i) => (
									<option key={i * Math.random()} value={e.id}>
										{e.description}
									</option>
								))}
							</TextField>
						)}

						{location.pathname !== '/oportunidades' && (
							<Select
								displayEmpty
								name='filter'
								id='filter'
								value={formik.values.filter}
								onChange={formik.handleChange}
								input={<OutlinedInput />}
								inputProps={{ 'aria-label': 'Without label' }}
								sx={{
									width: smallScreen ? 150 : 250,
									maxWidth: 250,
									ml: 1,
									height: '92%',
									alignSelf: 'end',
								}}
							>
								<MenuItem disabled value={0}>
									<em>Asesores...</em>
								</MenuItem>
								{advisors.map(e => (
									<MenuItem key={e.id} value={e.id}>
										{e.fullName}
									</MenuItem>
								))}
							</Select>
						)}
					</Box>
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<Tooltip title='Reiniciar'>
							<IconButton
								aria-label='Resetear lista'
								sx={{ ml: '10px' }}
								onClick={handleResetFilters}
							>
								<ReplayIcon />
							</IconButton>
						</Tooltip>
						<LoadingButton
							type='submit'
							aria-label='Buscar'
							loading={isLoading}
							sx={{ ml: '10px' }}
							variant='contained'
						>
							Buscar
						</LoadingButton>
					</div>
				</form>
			</Card>
			<ToastContainer />
		</>
	);
};

function validationSchema() {
	return Yup.object({
		search: Yup.string(),
		zone: Yup.number(),
		filter: Yup.number(),
		selectedZone: Yup.string(),
	});
}

function initialValues() {
	return {
		search: '',
		zone: 0,
		filter: 0,
		selectedZone: '',
	};
}

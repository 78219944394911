import { authAxios } from '../../../utils/authAxios';
import { GET_ALL_ADVISORS } from './users';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_ALL_CLIENTS = 'GET_ALL_CLIENTS';
export const GET_TOTAL_STATES_CLIENTS = 'GET_TOTAL_STATES_CLIENTS';
export const GET_TOTAL_CLIENTS = 'GET_TOTAL_CLIENTS';
export const CHANGE_ORDER_CLIENTS_CREATED = 'CHANGE_ORDER_CLIENTS_CREATED';
export const CHANGE_ORDER_CLIENTS_UPDATED = 'CHANGE_ORDER_CLIENTS_UPDATED';
export const CHANGE_ROWS_PER_PAGE = 'CHANGE_ROWS_PER_PAGE';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const CHANGE_STATE = 'CHANGE_STATE';
export const RESET_FILTERS = 'RESET_FILTERS';
export const ADD_SEARCH_QUERY = 'ADD_SEARCH_QUERY';
export const CHANGE_LOADER = 'CHANGE_LOADER';
export const CHANGE_ADVISOR = 'CHANGE_ADVISOR';
export const CHANGE_CLIENTS_STATUS = 'CHANGE_CLIENTS_STATUS';
export const GET_NEXT_ADVISOR = 'GET_NEXT_ADVISOR';
export const RESET_DATA = 'RESET_DATA';

export const UPDATE_STATE = 'UPDATE_STATE';

export const getAllClients = () => {
	return async function (dispatch, getState) {
		dispatch({ type: CHANGE_LOADER });
		const stateBefore = getState().allClients;
		const response = await authAxios(
			`${URL_API}/admin/clients/${stateBefore.page}/${stateBefore.rowsPerPage}/${stateBefore.status}/${stateBefore.typeOrder}/${stateBefore.order}?${stateBefore.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_CLIENTS,
			payload: response,
		});
	};
};

export const getTotalClientsAdmin = () => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/admin/total/states`, 'get');
		return dispatch({
			type: GET_TOTAL_CLIENTS,
			payload: response,
		});
	};
};

export const changeOrderUpdated = order => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		await dispatch({ type: CHANGE_ORDER_CLIENTS_UPDATED, payload: order });
		const newStates = getState().allClients;
		const response = await authAxios(
			`${URL_API}/admin/clients/${newStates.page}/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}?${newStates.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_CLIENTS,
			payload: response,
		});
	};
};

export const changeOrderCreated = order => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		await dispatch({ type: CHANGE_ORDER_CLIENTS_CREATED, payload: order });
		const newStates = getState().allClients;
		const response = await authAxios(
			`${URL_API}/admin/clients/${newStates.page}/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}?${newStates.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_CLIENTS,
			payload: response,
		});
	};
};

export const changeRowsPerPage = rowsPerPage => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		await dispatch({ type: CHANGE_ROWS_PER_PAGE, payload: rowsPerPage });
		const newStates = getState().allClients;
		const response = await authAxios(
			`${URL_API}/admin/clients/${newStates.page}/${rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}?${newStates.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_CLIENTS,
			payload: response,
		});
	};
};

export const changePage = newPage => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		await dispatch({ type: CHANGE_PAGE, payload: newPage });
		const newStates = getState().allClients;
		const response = await authAxios(
			`${URL_API}/admin/clients/${newPage}/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}?${newStates.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_CLIENTS,
			payload: response,
		});
	};
};

export const changeState = newState => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		await dispatch({ type: CHANGE_STATE, payload: newState });
		const newStates = getState().allClients;
		const response = await authAxios(
			`${URL_API}/admin/clients/${newStates.page}/${newStates.rowsPerPage}/${newState}/${newStates.typeOrder}/${newStates.order}?${newStates.querys}`,
			'get'
		);
		return dispatch({
			type: GET_ALL_CLIENTS,
			payload: response,
		});
	};
};

export const resetFilters = () => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER });
		const totalsNew = await authAxios(`${URL_API}/admin/total/states`, 'get');
		dispatch({
			type: GET_TOTAL_CLIENTS,
			payload: totalsNew,
		});
		const response = await authAxios(`${URL_API}/admin/clients/0/25/1/createdAt/desc?`, 'get');
		dispatch({ type: RESET_FILTERS });
		return dispatch({
			type: GET_ALL_CLIENTS,
			payload: response,
		});
	};
};

export const search = query => {
	return async (dispatch, getState) => {
		dispatch({ type: CHANGE_LOADER });
		dispatch({ type: ADD_SEARCH_QUERY, payload: query });

		const newStates = getState().allClients;

		const statesAdvisor = await authAxios(`${URL_API}/admin/total/states/advisor?${query}`, 'get');

		dispatch({ type: GET_TOTAL_CLIENTS, payload: statesAdvisor });

		const response = await authAxios(
			`${URL_API}/admin/clients/${newStates.page}/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}?&${query}`,
			'get'
		);

		return dispatch({
			type: GET_ALL_CLIENTS,
			payload: response,
		});
	};
};

export const getAdvisors = () => {
	return async dispatch => {
		const response = await authAxios(`${URL_API}/admin/advisors`, 'get');
		return dispatch({
			type: GET_ALL_ADVISORS,
			payload: response,
		});
	};
};

export const changeAdvisor = data => {
	return async function (dispatch, getState) {
		dispatch({ type: CHANGE_LOADER });
		await authAxios(`${URL_API}/admin/advisor/change`, 'put', null, data);
		const newStates = getState().allClients;
		const response = await authAxios(
			`${URL_API}/admin/clients/${newStates.page}/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}?${newStates.querys}`,
			'get'
		);
		const statesAdvisor = await authAxios(
			`${URL_API}/admin/total/states/advisor?${newStates.querys}`,
			'get'
		);
		dispatch({ type: GET_TOTAL_CLIENTS, payload: statesAdvisor });
		dispatch({ type: CHANGE_ADVISOR, payload: response });
	};
};

export const changeStatus = data => {
	return async function (dispatch, getState) {
		dispatch({ type: CHANGE_LOADER });
		dispatch({ type: UPDATE_STATE, payload: data });
		await authAxios(`${URL_API}/admin/state/change`, 'put', null, data);
		const newStates = getState().allClients;
		const response = await authAxios(
			`${URL_API}/admin/clients/0/${newStates.rowsPerPage}/${newStates.status}/${newStates.typeOrder}/${newStates.order}`,
			'get'
		);
		dispatch({ type: CHANGE_CLIENTS_STATUS, payload: response });
	};
};

export const createClient = data => {
	return async dispatch => {
		const response = await authAxios(`${URL_API}/admin/client`, 'post', null, data);
		return response;
	};
};

export const getClientDuplicated = async cellphone => {
	const response = await authAxios(`${URL_API}/admin/duplicated/data/${cellphone}`, 'get');
	return response;
};

export const downloadDataList = data => {
	return async function (dispatch, getState) {
		const newStates = getState().allClients;

		try {
			const response = await fetch(`${URL_API}/admin/download/csv/clients/prop`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ ...data, status: newStates.status }),
			});
			if (response.ok) {
				const blob = await response.blob();
				const url = URL.createObjectURL(blob);

				const date = new Date();
				const fileName = `datos-${('0' + date.getDate()).slice(-2)}-${(
					'0' +
					(date.getMonth() + 1)
				).slice(-2)}-${date.getFullYear()}.xlsx`;

				const a = document.createElement('a');
				a.href = url;
				a.download = fileName;
				a.style.display = 'none';
				document.body.appendChild(a);
				a.click();
				URL.revokeObjectURL(url);
			} else {
				console.error('Error al descargar el archivo Excel');
			}
		} catch (error) {
			console.error('Error al descargar el archivo CSV:', error);
		}
	};
};
export const downloadAllData = async data => {
	try {
		const response = await fetch(`${URL_API}/admin/download/csv/clients`, {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(data),
		});
		if (response.ok) {
			const blob = await response.blob();
			const url = URL.createObjectURL(blob);

			const a = document.createElement('a');
			a.href = url;
			a.download = 'datos.csv';
			a.style.display = 'none';
			document.body.appendChild(a);
			a.click();
			URL.revokeObjectURL(url);
		} else {
			console.error('Error al descargar el archivo CSV');
		}
	} catch (error) {
		console.error('Error al descargar el archivo CSV:', error);
	}
};

export const resetData = () => {
	return async function (dispatch) {
		return dispatch({
			type: RESET_DATA,
		});
	};
};

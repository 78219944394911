import { Box, Button, Container, Stack, SvgIcon, Typography } from '@mui/material';
import ArrowDownOnSquareIcon from '@heroicons/react/24/solid/ArrowDownOnSquareIcon';
import ArrowUpOnSquareIcon from '@heroicons/react/24/solid/ArrowUpOnSquareIcon';
import PlusIcon from '@heroicons/react/24/solid/PlusIcon';
import { useSelector } from 'react-redux';
import { useState } from 'react';

import { ClientSearch, ClientsList } from '../components/Clientes';
import { ModalCreateContact } from '../components/Modals';
import { useAuth } from '../hooks/useAuth';

import {
	search,
	changePage,
	changeState,
	resetFilters,
	changeRowsPerPage,
	changeOrderUpdated,
	changeOrderCreatedAt,
} from '../redux/actions/user/oportunidades';

export const Oportunidades = () => {
	const { user } = useAuth();

	const clients = useSelector(state => state.clientsAdvisor.allClients);
	const totalStates = useSelector(state => state.clientsAdvisor.total);
	const isLoading = useSelector(state => state.clientsAdvisor.loading);

	const page = useSelector(state => state.clientsAdvisor.page);
	const order = useSelector(state => state.clientsAdvisor.order);
	const rowsPerPage = useSelector(state => state.clientsAdvisor.rowsPerPage);
	const countClients = useSelector(state => state.clientsAdvisor.countClients);

	const zonesLaplata = useSelector(state => state.zones.zonesLaplata);
	const zonesMendoza = useSelector(state => state.zones.zonesMendoza);

	const stateValue = useSelector(state => state.clientsAdvisor.status);

	const [selected, setSelected] = useState([]);
	const [selectAll, setSelectAll] = useState(false);

	const handleSelect = id => {
		setSelected([...selected, id]);
	};

	const handleUnselect = id => {
		let result = selected.filter(e => e !== id);
		setSelected(result);
	};

	const [open, setOpen] = useState(false);

	return (
		<>
			<Box
				component='main'
				sx={{
					flexGrow: 1,
					py: 8,
				}}
			>
				<Container maxWidth='xl'>
					<Stack spacing={3}>
						<Stack direction='row' justifyContent='space-between' spacing={4}>
							<Stack spacing={1}>
								<Typography variant='h4'>Oportunidades</Typography>
								<Stack alignItems='center' direction='row' spacing={1}>
									<Button
										color='inherit'
										disabled
										startIcon={
											<SvgIcon fontSize='small'>
												<ArrowUpOnSquareIcon />
											</SvgIcon>
										}
									>
										Importar
									</Button>
									<Button
										color='inherit'
										disabled
										startIcon={
											<SvgIcon fontSize='small'>
												<ArrowDownOnSquareIcon />
											</SvgIcon>
										}
									>
										Exportar
									</Button>
								</Stack>
							</Stack>
							<span>
								<Button
									startIcon={
										<SvgIcon fontSize='small'>
											<PlusIcon />
										</SvgIcon>
									}
									variant='contained'
									onClick={() => setOpen(true)}
								>
									Añadir
								</Button>
							</span>
						</Stack>
						{user.areaRol !== 1 ? (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									marginTop: '40px',
									fontSize: '18px',
								}}
							>
								No eres asesor
							</div>
						) : (
							<>
								<ClientSearch
									search={search}
									stateValue={stateValue}
									changeState={changeState}
									totalStates={totalStates}
									zonesLaplata={zonesLaplata}
									resetFilters={resetFilters}
									zonesMendoza={zonesMendoza}
								/>

								<ClientsList
									page={page}
									order={order}
									showAdvisor={false}
									selected={selected}
									itemClients={clients}
									selectAll={selectAll}
									isLoading={isLoading}
									changePage={changePage}
									rowsPerPage={rowsPerPage}
									setSelected={setSelected}
									countClients={countClients}
									setSelectAll={setSelectAll}
									handleSelect={handleSelect}
									handleUnselect={handleUnselect}
									changeRowsPerPage={changeRowsPerPage}
									changeOrderUpdated={changeOrderUpdated}
									changeOrderCreatedAt={changeOrderCreatedAt}
								/>
							</>
						)}
					</Stack>
				</Container>
			</Box>
			<ModalCreateContact
				open={open}
				setOpen={setOpen}
				zonesLaplata={zonesLaplata}
				zonesMendoza={zonesMendoza}
			/>
		</>
	);
};

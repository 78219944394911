import {
	GET_ALL_CLIENTS,
	GET_TOTAL_CLIENTS,
	CHANGE_ORDER_CLIENTS_UPDATED,
	CHANGE_ORDER_CLIENTS_CREATED,
	CHANGE_ROWS_PER_PAGE,
	CHANGE_PAGE,
	CHANGE_STATE,
	RESET_FILTERS,
	ADD_SEARCH_QUERY,
	CHANGE_LOADER,
	CHANGE_ADVISOR,
	CHANGE_CLIENTS_STATUS,
	GET_NEXT_ADVISOR,
	RESET_DATA,
	UPDATE_STATE,
} from '../../actions/admin/clients';
import { GET_ALL_ADVISORS } from '../../actions/admin/users';

const initialState = {
	allClients: [],
	page: 0,
	status: 1,
	total: [],
	order: 'desc',
	typeOrder: 'createdAt',
	rowsPerPage: 25,
	querys: '',
	countClients: 0,
	loading: false,
	advisors: [],
	nextAdvisor: null,
};

export const allClients = (state = initialState, { type, payload }) => {
	switch (type) {
		case GET_ALL_CLIENTS:
			return {
				...state,
				allClients: payload,
				loading: false,
			};

		case GET_TOTAL_CLIENTS:
			return {
				...state,
				total: payload,
				countClients: payload.sinContactar,
			};
		case CHANGE_ORDER_CLIENTS_UPDATED:
			return {
				...state,
				typeOrder: 'updatedAt',
				order: payload,
			};
		case CHANGE_ORDER_CLIENTS_CREATED:
			return {
				...state,
				typeOrder: 'createdAt',
				order: payload,
			};
		case CHANGE_ROWS_PER_PAGE:
			return {
				...state,
				rowsPerPage: payload,
			};
		case CHANGE_PAGE:
			return {
				...state,
				page: payload,
			};
		case CHANGE_STATE:
			return {
				...state,
				status: payload,
				countClients:
					payload === 1
						? state.total.sinContactar
						: payload === 2
						? state.total.telefonico
						: payload === 3
						? state.total.presencial
						: payload === 4
						? state.total.alta
						: payload === 5
						? state.total.baja
						: payload === 6
						? state.total.reservado
						: state.total.reservasCaidas,
			};
		case RESET_FILTERS:
			return {
				...state,
				page: 0,
				status: 1,
				order: 'desc',
				rowsPerPage: 25,
				querys: '',
				countClients: state.total.sinContactar,
			};
		case ADD_SEARCH_QUERY:
			return {
				...state,
				querys: payload,
			};
		case CHANGE_LOADER:
			return {
				...state,
				loading: true,
			};
		case GET_ALL_ADVISORS:
			return {
				...state,
				advisors: payload,
			};
		case CHANGE_ADVISOR:
			return {
				...state,
				allClients: payload,
				loading: false,
			};
		case CHANGE_CLIENTS_STATUS:
			return {
				...state,
				allClients: payload,
				loading: false,
			};
		case GET_NEXT_ADVISOR:
			return {
				...state,
				nextAdvisor: payload,
			};
		case RESET_DATA:
			return {
				...state,
				allClients: [],
				page: 0,
				status: 1,
				total: [],
				order: 'desc',
				typeOrder: 'createdAt',
				rowsPerPage: 25,
				querys: '',
				countClients: 0,
				loading: false,
				advisors: [],
				nextAdvisor: null,
			};

		case UPDATE_STATE:
			let newTotal =
				state.status === 1
					? { ...state.total, sinContactar: state.total.sinContactar - payload.ids.length }
					: state.status === 2
					? { ...state.total, telefonico: state.total.telefonico - payload.ids.length }
					: state.status === 3
					? { ...state.total, presencial: state.total.presencial - payload.ids.length }
					: state.status === 4
					? { ...state.total, alta: state.total.alta - payload.ids.length }
					: state.status === 5
					? { ...state.total, baja: state.total.baja - payload.ids.length }
					: state.status === 6
					? { ...state.total, reservado: state.total.reservado - payload.ids.length }
					: { ...state.total, reservasCaidas: state.total.reservasCaidas - payload.ids.length };

			let actualTotal =
				payload.state === 1
					? { ...newTotal, sinContactar: state.total.sinContactar + payload.ids.length }
					: payload.state === 2
					? { ...newTotal, telefonico: state.total.telefonico + payload.ids.length }
					: payload.state === 3
					? { ...newTotal, presencial: state.total.presencial + payload.ids.length }
					: payload.state === 4
					? { ...newTotal, alta: state.total.alta + payload.ids.length }
					: payload.state === 5
					? { ...newTotal, baja: state.total.baja + payload.ids.length }
					: payload.state === 6
					? { ...newTotal, reservado: state.total.reservado + payload.ids.length }
					: { ...newTotal, reservasCaidas: state.total.reservasCaidas + payload.ids.length };

			return {
				...state,
				total: actualTotal,
			};

		default:
			return state;
	}
};

import axios from 'axios';
import { removeToken, getToken, hasExpiredToken } from '../redux/actions/token';

export async function authAxios(url, type, params, body = {}) {
	const token = getToken();

	const logout = () => {
		removeToken();
		window.location.replace('/login');
	};

	if (!token) logout();
	else if (hasExpiredToken(token)) logout();
	else {
		const config = {
			...params?.headers,
			Authorization: `Bearer ${token}`,
		};

		const configAxios = {
			...params,
			headers: {
				...params?.headers,
				Authorization: `Bearer ${token}`,
			},
		};
		try {
			// eslint-disable-next-line default-case
			switch (type) {
				case 'post': {
					let response = null;
					try {
						const res = await fetch(url, {
							method: 'POST',
							headers: { ...config, 'Content-Type': 'application/json' },
							body: JSON.stringify(body),
						});
						if (!res.ok) {
							if (res.status === 409) return res.status;
							else throw new Error('Error HTTP: ' + res.status);
						}
						const contentType = res.headers.get('content-type');
						if (contentType && contentType.includes('application/json')) {
							response = await res.json();
						} else {
							response = await res.text();
						}
					} catch (error) {
						console.error(error);
						return error;
					}
					return response;
				}
				case 'get': {
					let response = null;

					try {
						await fetch(url, {
							method: 'GET',
							headers: config,
						})
							.then(res => {
								if (!res.ok) throw new Error('Error HTTP: ' + res.status);
								return res.json();
							})
							.then(data => (response = data))
							.catch(error => {
								console.error(error);
								return error;
							});
					} catch (error) {
						console.log(error);
						return error;
					}
					return response;
				}
				case 'delete': {
					let response = null;
					try {
						const res = await fetch(url, {
							method: 'DELETE',
							headers: { ...config, 'Content-Type': 'application/json' },
							body: JSON.stringify(body),
						});
						if (!res.ok) {
							throw new Error('Error HTTP: ' + res.status);
						}
						const contentType = res.headers.get('content-type');
						if (contentType && contentType.includes('application/json')) {
							response = await res.json();
						} else {
							response = await res.text();
						}
					} catch (error) {
						console.error(error);
						return error;
					}
					return response;
				}
				case 'put': {
					let response = null;
					try {
						const res = await fetch(url, {
							method: 'PUT',
							headers: { ...config, 'Content-Type': 'application/json' },
							body: JSON.stringify(body),
						});

						if (!res.ok) {
							const errorData = await res.json();
							const error = new Error('Request failed');
							error.data = errorData;
							throw error;
						}

						const contentType = res.headers.get('content-type');

						if (contentType && contentType.includes('application/json')) {
							response = await res.json();
						} else {
							response = await res.text();
						}
					} catch (error) {
						return { success: false, error: error.data || error.message };
					}
					return { success: true, response };
				}
				case 'axios': {
					const response = await axios.post(url, body, configAxios);
					return response;
				}
				case 'axios-put': {
					const response = await axios.put(url, body, configAxios);
					return response;
				}
			}
		} catch (error) {
			return error;
		}
	}
}

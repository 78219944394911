import React, { useState } from 'react';
import { useFormik } from 'formik';
import axios from 'axios';
import {
	Box,
	Card,
	CardHeader,
	CardContent,
	Unstable_Grid2 as Grid,
	TextField,
	Divider,
	CardActions,
	Typography,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import * as Yup from 'yup';

import { provinces, currencies, paymentsType } from '../../lib';
import { sendDataReserva } from '../../redux/actions/user/ficha';
import { formatDateToDateTimeLocal } from '../../utils/formatDate';

export const TemplateFicha = ({ data, user, lote, toast }) => {
	const [isLoading, setIsLoading] = useState(false);

	const formik = useFormik({
		initialValues: initialValues(data, user, lote),
		validationSchema: validationSchema(),
		validateOnChange: false,
		onSubmit: async formValue => {
			try {
				setIsLoading(true);

				const userip = await axios.get('https://api.ipify.org/?format=json');

				const response = await sendDataReserva(formValue, data, lote, userip.data.ip);
				if (response === 'Error al descargar/enviar la reserva') throw new Error();
				setIsLoading(false);
				window.history.back();
				return toast.success('Reserva enviada a administración con éxito', { theme: 'colored' });
			} catch (error) {
				console.log(error);
				setIsLoading(false);
				return toast.error('Error, intentelo de nuevo.', { theme: 'colored' });
			}
		},
	});

	// Previene el envío del formulario al presionar Enter
	const handleKeyPress = event => {
		if (event.key === 'Enter') {
			event.preventDefault(); // Previene el submit
		}
	};

	return (
		<div style={{ width: '70%' }}>
			<form onSubmit={formik.handleSubmit} onKeyDown={handleKeyPress}>
				<Card>
					<CardHeader
						subheader='Datos personales firmante uno'
						title='Datos para ficha de reserva'
					/>
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Nombre'
										name='name'
										value={formik.values.name}
										onChange={formik.handleChange}
										error={formik.errors.name}
									/>
								</Grid>

								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Apellido'
										name='lastName'
										value={formik.values.lastName}
										onChange={formik.handleChange}
										error={formik.errors.lastName}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Tipo de doc'
										name='typeDoc'
										value={formik.values.typeDoc}
										onChange={formik.handleChange}
										error={formik.errors.typeDoc}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Numero doc'
										name='numberId'
										value={formik.values.numberId}
										onChange={formik.handleChange}
										error={formik.errors.numberId}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Fecha de nacimiento'
										name='birthdate'
										type='date'
										value={formik.values.birthdate}
										onChange={formik.handleChange}
										error={!!formik.errors.birthdate}
										InputLabelProps={{ shrink: true }}
									/>

									{typeof formik.errors.birthdate === 'string' && (
										<Typography
											fontSize={12}
											color='red'
											sx={{ position: 'absolute', transform: 'translateY(-75px)' }}
										>
											{formik.errors.birthdate}
										</Typography>
									)}
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										disabled
										fullWidth
										label='Telefóno celular'
										name='cellphone'
										value={formik.values.cellphone}
										onChange={formik.handleChange}
										error={formik.errors.cellphone}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Celular 2'
										name='numeroCelular2Firmante'
										value={formik.values.numeroCelular2Firmante}
										onChange={formik.handleChange}
										error={formik.errors.numeroCelular2Firmante}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Dirección de email'
										name='email'
										value={formik.values.email}
										onChange={formik.handleChange}
										error={!!formik.errors.email}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Ocupación'
										name='job'
										value={formik.values.job}
										onChange={formik.handleChange}
										error={!!formik.errors.job}
									/>
								</Grid>

								<Grid xs={12} md={9}>
									<TextField
										fullWidth
										label='Dirección'
										name='address'
										value={formik.values.address}
										onChange={formik.handleChange}
										error={!!formik.errors.address}
									/>
								</Grid>

								<Grid xs={12} md={3}>
									<TextField
										fullWidth
										label='Codigo postal'
										name='postalCode'
										value={formik.values.postalCode}
										onChange={formik.handleChange}
										error={!!formik.errors.postalCode}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Localidad'
										name='locality'
										value={formik.values.locality}
										onChange={formik.handleChange}
										error={formik.errors.locality}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Provincia'
										name='province'
										select
										SelectProps={{ native: true }}
										value={formik.values.province}
										onChange={formik.handleChange}
										error={formik.errors.province}
										InputLabelProps={{ shrink: true }}
									>
										{provinces.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<CardHeader subheader='Datos personales firmante dos (opcional)' />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Nombre'
										name='nombreFirmante2'
										value={formik.values.nombreFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.nombreFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Apellido'
										name='apellidoFirmante2'
										value={formik.values.apellidoFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.apellidoFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Tipo de doc'
										name='tipoDocFirmante2'
										value={formik.values.tipoDocFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.tipoDocFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Numero doc'
										name='numeroDocFirmante2'
										value={formik.values.numeroDocFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.numeroDocFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={4}>
									<TextField
										fullWidth
										label='Fecha de nacimiento'
										name='fechaNacFirmante2'
										type='date'
										value={formik.values.fechaNacFirmante2}
										onChange={formik.handleChange}
										error={!!formik.errors.fechaNacFirmante2}
										InputLabelProps={{ shrink: true }}
									/>

									{typeof formik.errors.fechaNacFirmante2 === 'string' && (
										<Typography
											fontSize={12}
											color='red'
											sx={{ position: 'absolute', transform: 'translateY(-75px)' }}
										>
											{formik.errors.fechaNacFirmante2}
										</Typography>
									)}
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Telefóno celular'
										name='numeroCelularFirmante2'
										value={formik.values.numeroCelularFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.numeroCelularFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Celular 2'
										name='numeroCelular2Firmante2'
										value={formik.values.numeroCelular2Firmante2}
										onChange={formik.handleChange}
										error={formik.errors.numeroCelular2Firmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Dirección de email'
										name='emailFirmante2'
										value={formik.values.emailFirmante2}
										onChange={formik.handleChange}
										error={!!formik.errors.emailFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Ocupación'
										name='ocupacion2'
										value={formik.values.ocupacion2}
										onChange={formik.handleChange}
										error={!!formik.errors.ocupacion2}
									/>
								</Grid>

								<Grid xs={12} md={9}>
									<TextField
										fullWidth
										label='Dirección'
										name='direccionFirmante2'
										value={formik.values.direccionFirmante2}
										onChange={formik.handleChange}
										error={!!formik.errors.direccionFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={3}>
									<TextField
										fullWidth
										label='Codigo postal'
										name='codigoPostalFirmante2'
										value={formik.values.codigoPostalFirmante2}
										onChange={formik.handleChange}
										error={!!formik.errors.codigoPostalFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Localidad'
										name='localidadFirmante2'
										value={formik.values.localidadFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.localidadFirmante2}
									/>
								</Grid>

								<Grid xs={12} md={6}>
									<TextField
										fullWidth
										label='Provincia'
										name='provinciaFirmante2'
										select
										SelectProps={{ native: true }}
										value={formik.values.provinciaFirmante2}
										onChange={formik.handleChange}
										error={formik.errors.provinciaFirmante2}
										InputLabelProps={{ shrink: true }}
									>
										{provinces.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<CardHeader subheader='Lote' />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={8}>
									<TextField
										fullWidth
										disabled
										label='Loteo'
										name='nombreLote'
										value={formik.values.nombreLote}
										error={formik.errors.nombreLote}
									/>
								</Grid>
								<Grid xs={12} md={4}>
									<TextField
										disabled
										fullWidth
										label='N° de lote'
										name='numeroLote'
										value={formik.values.numeroLote}
										error={formik.errors.numeroLote}
									/>
								</Grid>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Dirección'
										name='direccionLote'
										value={formik.values.direccionLote}
										onChange={formik.handleChange}
										error={formik.errors.direccionLote}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<CardHeader subheader='Pago' />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='Reserva'
										type='datetime-local'
										name='reservaFecha'
										value={formik.values.reservaFecha}
										onChange={formik.handleChange}
										error={formik.errors.reservaFecha}
										InputLabelProps={{ shrink: true }}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Vencimiento'
										name='vencimientoFecha'
										type='datetime-local'
										value={formik.values.vencimientoFecha}
										onChange={formik.handleChange}
										error={!!formik.errors.vencimientoFecha}
										InputLabelProps={{ shrink: true }}
									/>

									{typeof formik.errors.vencimientoFecha === 'string' && (
										<Typography
											fontSize={12}
											color='red'
											sx={{ position: 'absolute', transform: 'translateY(-75px)' }}
										>
											{formik.errors.vencimientoFecha}
										</Typography>
									)}
								</Grid>
								<Grid xs={12} md={2}>
									<TextField
										fullWidth
										label='Moneda'
										name='currency'
										select
										SelectProps={{ native: true }}
										value={formik.values.currency}
										onChange={formik.handleChange}
										error={formik.errors.currency}
										InputLabelProps={{ shrink: true }}
									>
										{currencies.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</TextField>
								</Grid>
								<Grid xs={6} md={5}>
									<TextField
										fullWidth
										label='Valor total'
										name='valorTotal'
										value={
											formik.values.valorTotal
												? Number(formik.values.valorTotal).toLocaleString('es-ES')
												: ''
										}
										onChange={e => {
											// Eliminamos el formato y obtenemos el valor crudo
											const rawValue = e.target.value.replace(/\./g, '').replace(/,/g, '');
											if (!isNaN(rawValue)) {
												// Guardamos el valor sin formato en Formik
												formik.setFieldValue('valorTotal', rawValue);
											}
										}}
										error={formik.errors.valorTotal}
									/>
								</Grid>
								<Grid xs={6} md={5}>
									<TextField
										fullWidth
										label='Monto reservado'
										name='montoReservado'
										value={
											formik.values.montoReservado
												? Number(formik.values.montoReservado).toLocaleString('es-ES')
												: ''
										}
										onChange={e => {
											const rawValue = e.target.value.replace(/\./g, '').replace(/,/g, '');
											if (!isNaN(rawValue)) {
												formik.setFieldValue('montoReservado', rawValue);
											}
										}}
										error={formik.errors.montoReservado}
									/>
								</Grid>
								<Grid xs={6} md={12}>
									<TextField
										fullWidth
										label='Forma de pago'
										name='formaPago'
										select
										SelectProps={{ native: true }}
										value={formik.values.formaPago}
										onChange={formik.handleChange}
										error={formik.errors.formaPago}
										InputLabelProps={{ shrink: true }}
									>
										{paymentsType.map(option => (
											<option key={option.value} value={option.value}>
												{option.label}
											</option>
										))}
									</TextField>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<CardHeader subheader='Auto' />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='¿Entrega vehículo?'
										name='entregaVehiculo'
										value={formik.values.entregaVehiculo}
										onChange={formik.handleChange}
										error={formik.errors.entregaVehiculo}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Dominio'
										name='dominioVehiculo'
										value={formik.values.dominioVehiculo}
										onChange={formik.handleChange}
										error={formik.errors.dominioVehiculo}
									/>
								</Grid>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='Modelo'
										name='modeloVehiculo'
										value={formik.values.modeloVehiculo}
										onChange={formik.handleChange}
										error={formik.errors.modeloVehiculo}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Año'
										name='anoVehiculo'
										value={formik.values.anoVehiculo}
										onChange={formik.handleChange}
										error={formik.errors.anoVehiculo}
									/>
								</Grid>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='¿Tiene prenda?'
										name='tienePrenda'
										value={formik.values.tienePrenda}
										onChange={formik.handleChange}
										error={formik.errors.tienePrenda}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Monto prenda'
										name='montoPrenda'
										value={formik.values.montoPrenda}
										onChange={formik.handleChange}
										error={formik.errors.montoPrenda}
									/>
								</Grid>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='¿Multas impagas?'
										name='multasImpagas'
										value={formik.values.multasImpagas}
										onChange={formik.handleChange}
										error={formik.errors.multasImpagas}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Monto adeudado'
										name='montoMultas'
										value={formik.values.montoMultas}
										onChange={formik.handleChange}
										error={formik.errors.montoMultas}
									/>
								</Grid>
								<Grid xs={12} md={6.5}>
									<TextField
										fullWidth
										label='¿Debe patente?'
										name='patentesImpagas'
										value={formik.values.patentesImpagas}
										onChange={formik.handleChange}
										error={formik.errors.patentesImpagas}
									/>
								</Grid>
								<Grid xs={12} md={5.5}>
									<TextField
										fullWidth
										label='Monto adeudado'
										name='montoPatentes'
										value={formik.values.montoPatentes}
										onChange={formik.handleChange}
										error={formik.errors.montoPatentes}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />

					<CardHeader subheader='Observaciones finales' />
					<CardContent sx={{ pt: 0 }}>
						<Box sx={{ m: -1.5 }}>
							<Grid container spacing={3}>
								<Grid xs={12} md={12}>
									<TextField
										fullWidth
										label='Observaciones'
										name='observaciones'
										value={formik.values.observaciones}
										onChange={formik.handleChange}
										error={!!formik.errors.observaciones}
										multiline
										rows={4}
									/>
								</Grid>
							</Grid>
						</Box>
					</CardContent>
					<Divider />
					<CardActions sx={{ justifyContent: 'flex-end' }}>
						<LoadingButton loading={isLoading} variant='contained' type='submit'>
							Enviar ficha
						</LoadingButton>
					</CardActions>
				</Card>
			</form>
		</div>
	);
};

function initialValues(info, user, lote) {
	return {
		asesor: user.fullName,
		asesorEmail: user.email,
		name: info.clientData.name,
		lastName: info.clientData.lastName ? info.clientData.lastName : '',
		typeDoc:
			info.clientData.TypeDocId === 1
				? 'DNI'
				: info.clientData.TypeDocId === 2
				? 'Pasaporte'
				: 'LE',
		numberId: info.clientData.numberId ? info.clientData.numberId : '',
		birthdate: info.clientData.birthdate ? info.clientData.birthdate : '',
		cellphone: info.clientData.cellphone,
		numeroCelular2Firmante: '',
		email: info.clientData.email ? info.clientData.email : '',
		address: info.clientData.address ? info.clientData.address : '',
		postalCode: info.clientData.postalCode ? info.clientData.postalCode : '',
		locality: info.clientData.locality ? info.clientData.locality : '',
		province: info.clientData.province ? info.clientData.province : '',
		job: info.clientData.job ? info.clientData.job : '',
		nombreFirmante2: '',
		apellidoFirmante2: '',
		tipoDocFirmante2: '',
		numeroDocFirmante2: '',
		fechaNacFirmante2: '',
		numeroCelularFirmante2: '',
		numeroCelular2Firmante2: '',
		emailFirmante2: '',
		ocupacion2: '',
		direccionFirmante2: '',
		codigoPostalFirmante2: '',
		localidadFirmante2: '',
		provinciaFirmante2: '',
		nombreLote: info.developmentData.name,
		numeroLote: lote,
		direccionLote: '',
		manzanaLote: '',
		reservaFecha: formatDateToDateTimeLocal(new Date()),
		vencimientoFecha: '',
		montoReservado: '',
		currency: '$',
		valorTotal: '',
		formaPago: '',
		entregaVehiculo: 'No',
		dominioVehiculo: '',
		modeloVehiculo: '',
		anoVehiculo: '',
		tienePrenda: '',
		montoPrenda: '',
		multasImpagas: '',
		montoMultas: '',
		patentesImpagas: '',
		montoPatentes: '',
		observaciones: '',
	};
}

function validationSchema() {
	const today = new Date();
	const oneYearFromNow = new Date();
	oneYearFromNow.setFullYear(today.getFullYear() + 1);

	const minDate = new Date();
	minDate.setFullYear(today.getFullYear() - 100); // Limite de 100 años en el pasado
	const maxDate = new Date();
	maxDate.setFullYear(today.getFullYear() - 18); // Minimo 18 años de edad

	return Yup.object({
		asesor: Yup.string().required(true),
		asesorEmail: '',
		name: Yup.string().required(true),
		lastName: Yup.string().required(true),
		typeDoc: Yup.string().required(true),
		numberId: Yup.number().required(true),
		birthdate: Yup.date()
			.required(true)
			.min(minDate, 'El firmante es mayor a los 100 años')
			.max(maxDate, 'El firmante debe tener al menos 18 años'),
		cellphone: Yup.string().required(true),
		numeroCelular2Firmante: '',
		email: Yup.string().email().required(true),
		job: Yup.string().required(true),
		address: Yup.string().required(true),
		postalCode: Yup.string().required(true),
		locality: Yup.string().required(true),
		province: Yup.string().required(true),
		nombreFirmante2: '',
		apellidoFirmante2: '',
		tipoDocFirmante2: '',
		numeroDocFirmante2: '',
		fechaNacFirmante2: Yup.date()
			.min(minDate, 'El firmante es mayor a los 100 años')
			.max(maxDate, 'El firmante debe tener al menos 18 años'),
		numeroCelularFirmante2: '',
		numeroCelular2Firmante2: '',
		emailFirmante2: '',
		ocupacion2: '',
		direccionFirmante2: '',
		codigoPostalFirmante2: '',
		localidadFirmante2: '',
		provinciaFirmante2: '',
		nombreLote: '',
		numeroLote: '',
		direccionLote: '',
		manzanaLote: '',
		reservaFecha: Yup.date().required(true),
		vencimientoFecha: Yup.date()
			.required(true)
			.min(today, 'La fecha de vencimiento no puede ser anterior a hoy')
			.max(oneYearFromNow, 'La fecha de vencimiento no puede ser mayor a un año desde hoy'),
		currency: Yup.string().required(true),
		montoReservado: Yup.string().required(true),
		valorTotal: Yup.string().required(true),
		formaPago: Yup.string().required(true),
		entregaVehiculo: '',
		dominioVehiculo: '',
		modeloVehiculo: '',
		anoVehiculo: '',
		tienePrenda: '',
		montoPrenda: '',
		multasImpagas: '',
		montoMultas: '',
		patentesImpagas: '',
		montoPatentes: '',
		observaciones: '',
	});
}

import { authAxios } from '../../utils/authAxios';
import { getClientDuplicated } from './admin/clients';

const URL_API = process.env.REACT_APP_URL_API;

export const GET_CLIENT_DATA = 'GET_CLIENT_DATA';
export const CHANGE_LOADER = 'CHANGE_LOADER';
export const GET_HISTORY_CLIENT = 'GET_HISTORY_CLIENT';
export const GET_LOTS_CLIENT = 'GET_LOTS_CLIENT';
export const DELETE_RESERVA = 'DELETE_RESERVA';
export const ADD_IMAGE_DNI = 'ADD_IMAGE_DNI';

export const getDataClient = param => {
	return async function (dispatch) {
		dispatch({ type: CHANGE_LOADER });
		const response = await authAxios(`${URL_API}/client/clientDetail/${param.id}/`, 'get');
		return dispatch({
			type: GET_CLIENT_DATA,
			payload: response,
		});
	};
};

export const getLotsClient = param => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/lots/${param.id}`, 'get');
		return dispatch({
			type: GET_LOTS_CLIENT,
			payload: response,
		});
	};
};

export const getHistoryClient = id => {
	return async function (dispatch) {
		const response = await authAxios(`${URL_API}/client/clientHistory/${id}`, 'get');
		return dispatch({
			type: GET_HISTORY_CLIENT,
			payload: response,
		});
	};
};

export const updateClient = (id, data) => {
	return async function (dispatch) {
		try {
			const result = await authAxios(`${URL_API}/client/updateClient/${id}`, 'put', null, data);

			if (!result.success) {
				if (result.error.name === 'SequelizeUniqueConstraintError') {
					const clientDuplicated = await getClientDuplicated(data.cellphone);

					return { success: false, clientDuplicated };
				}
			}

			const response = await authAxios(`${URL_API}/client/clientDetail/${id}/`, 'get');

			const responseHistory = await authAxios(`${URL_API}/client/clientHistory/${id}`, 'get');

			dispatch({
				type: GET_HISTORY_CLIENT,
				payload: responseHistory,
			});

			dispatch({
				type: GET_CLIENT_DATA,
				payload: response,
			});

			return { success: true };
		} catch (error) {
			console.log(error);
			return error;
		}
	};
};

export const deleteReserva = data => {
	return async function (dispatch, getState) {
		let newStateLots = getState().clientDetail;
		await authAxios(
			`${URL_API}/client/eliminar/reserva/${data.ContactId}/${data.DevelopmentId}/${data.numberLot}`,
			'delete'
		);
		let lots = newStateLots.lots.filter(e => e.numberLot !== data.numberLot);
		return dispatch({
			type: GET_LOTS_CLIENT,
			payload: lots,
		});
	};
};

export const updatePassword = data => {
	return async function () {
		try {
			await authAxios(`${URL_API}/client/change/password`, 'put', null, data);
		} catch (error) {
			throw new Error(error);
		}
	};
};

export const addDniImages = async (data, part, contactId) => {
	const params = { 'Content-Type': 'multipart/form-data' };

	try {
		await authAxios(`${URL_API}/client/dni/${part}/${contactId}`, 'axios', params, data);

		return async function (dispatch) {
			const response = await authAxios(`${URL_API}/client/clientHistory/${contactId}`, 'get');
			return dispatch({
				type: GET_HISTORY_CLIENT,
				payload: response,
			});
		};
	} catch (error) {
		throw new Error(error);
	}
};

export const deleteDniImages = async (part, contactId) => {
	try {
		await authAxios(`${URL_API}/client/dni/${part}/${contactId}`, 'delete');

		return async function (dispatch) {
			const response = await authAxios(`${URL_API}/client/clientHistory/${contactId}`, 'get');
			return dispatch({
				type: GET_HISTORY_CLIENT,
				payload: response,
			});
		};
	} catch (error) {
		throw new Error(error);
	}
};

export const currencies = [
	{
		value: 'ARS',
		label: '$',
	},
	{
		value: 'USD',
		label: 'U$D',
	},
	{
		value: 'EUR',
		label: '€',
	},
	{
		value: 'BTC',
		label: '฿',
	},
];
